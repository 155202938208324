import React, { useEffect, useState } from 'react';
import './styles.css';
import { BiCaretLeft, BiCaretRight } from 'react-icons/bi';
import slide1 from '../../assets/slide1.png'
import slide6 from '../../assets/slide6.png'
import slide2 from '../../assets/slide2.png'
import slide3 from '../../assets/slide3.png'


const ImageSlider = () => {
    const sliderArray = [
        {
            "id": 1, "image": slide1
        },
        {
            "id": 2, "image": slide2
        },
        {
            "id": 3, "image": slide3
        },
        {
            "id": 6, "image": slide6
        }
    ];

    const [current, setCurrent] = useState(0);
    const length = sliderArray.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    useEffect(() => {
        const interval = setInterval(nextSlide, 6000);
        return () => clearInterval(interval)
    })

    if (!Array.isArray(sliderArray) || length <= 0) {
        return null;
    }

    return (
        <section className="slider">
            <BiCaretRight className="right-arrow" onClick={nextSlide} size={32} color={'#FFF'} />
            <BiCaretLeft className="left-arrow" onClick={prevSlide} size={32} color={'#FFF'} />

            {sliderArray.map((item, index) => {
                return (
                    <div className={index === current ? 'slide active' : 'slide'} key={index}>
                        {index === current && (
                            <img key={item.id} src={item.image} className="image" alt='img-carousel' />
                        )}
                    </div>
                )
            })}
        </section>
    );
}
export default ImageSlider;