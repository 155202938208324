import gifLoading from '../../assets/Loading.svg'
import Modal from '../Modal';
import { useLoading } from '../../hooks/useLoading'

export default function Loading({ width = 100 }) {
    const { loading, setLoading } = useLoading();

    return (
        <Modal
            isOpen={loading}
            setIsOpen={setLoading}
            closeButton={false}
        >
            <img src={gifLoading} alt="loading" width={width} />
        </Modal>
    );
}