import { useEffect } from 'react';
import './styles.css';

const ESCAPE_KEYCODE = 27;
const Modal = ({ children, isOpen, setIsOpen, id = "modal", closeButton = true, backdropClose = false, escapeClose = false }) => {

    useEffect(() => {
        if (!window || !escapeClose) return;

        const keyUpListener = (e) => {
            if(e.keyCode === ESCAPE_KEYCODE) setIsOpen(false);
        }
        window.addEventListener('keyup', keyUpListener)
        
        return () => window.removeEventListener('keyup', keyUpListener)
    }, []);

    const handleBackDropClick = (e) => {
        if (e) e.preventDefault();
        if (e.target.id !== id || !backdropClose) return;
        setIsOpen(false);
    }

    if (!isOpen) return null;
    return (
        <div id={id} className="backdrop" onClick={handleBackDropClick}>
            <div className="modal">
                {closeButton ? <button type="button" className="modal-close" onClick={() => setIsOpen(false)} /> : null}
                {children}
            </div>
        </div>
    )
}
export default Modal;