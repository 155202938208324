import React from 'react';
import './styles.css';
import image from '../../assets/sobre-sete.jpg'

const Sobre = ({ title }) => {
    return (
        <section className="sobre">
            <h1 id="sobre" className="titulo-white">{title}</h1>
            <p className="subtitulo-white"> conheça mais sobre a sete informática.</p>

            <div className="container grid-sobre">
                <div className="box-1-sobre">
                    <img className="img-sobre" src={image} alt="foto-sobre" />
                </div>
                <div className="box-2-sobre">
                    <h4 className="titulo-interno">Quem Somos ?</h4>
                    <p className="descricao descricao-interno">A Sete Informática atua no mercado de desenvolvimento de Sistemas da Informação desde o ano de 2011.
                        A Sete possui um excelente capital intelectual constituído por Analistas de alto desempenho,
                        todos certificados, com conhecimentos no mercado de inovação tecnológica, garantindo assim a qualidade
                        dos projetos desenvolvidos e a satisfação de nossos Clientes.</p>
                </div>
                <div className="box-3-sobre">
                    <h4 className="titulo-interno">Missão e Visão</h4>
                    <p className="descricao descricao-interno">Proporcionar praticidade no desenvolvimento, manutenção ou ampliação de
                        negócios
                        com sustentabilidade, através de projetos de Tecnologia da Informação de alto desempenho,
                        garantindo aos clientes a satisfação plena de seus objetivos estratégicos.
                        </p>
                </div>

                <div className="box-4-sobre">
                    <h4 className="titulo-interno">Valores</h4>
                    <ul className="descricao descricao-interno" style={{ listStyle: 'none' }}>
                        <li>–Ética</li>
                        <li>-Inovação</li>
                        <li>-Liderança</li>
                        <li>-Qualidade</li>
                        <li>-Comprometimento</li>
                        <li>-Responsabilidade socioambiental</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Sobre;