import React from "react";
import "./styles.css";

import ImageSlider from '../Slide';

const Inicio = () => (
    <section id="inicio">
        <ImageSlider />
    </section>
);
export default Inicio;