import React from "react";
import Site from "./pages/Site/site";
import { LoadingContextProvider } from "./context/LoadingContext";

const App = () => {
    return (
        <LoadingContextProvider>
            <Site />
        </LoadingContextProvider>
    );
}

export default App;