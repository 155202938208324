import React from "react";
//import { BsFacebook, AiFillInstagram, AiFillLinkedin } from "react-icons/fa"
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import './styles.css';


const Rodape = () => {
    return (
        <footer className="footer">
            <div className="footer-lista container">
                <div className="footer-item-historia footer-item">
                    <h3>Nossa História</h3>
                    <p>Desde 17/02/2011 a Sete Informática atua no mercado estadual de desenvolvimento de sistemas de
                        informação voltados para a gestão portuária. A SETE possui um
                        excelente capital intelectual constituído por analistas de alto desempenho, todos certificados,
                        com
                        conhecimentos no
                        mercado de inovação tecnológica, garantindo assim a qualidade dos projetos desenvolvidos e a
                        satisfação
                        dos clientes.</p>
                </div>

                <div className="footer-item">
                    <h3>Contatos</h3>
                    <ul>
                        <li>- contato@sete.inf.br</li>
                        <li>- 85 30858827</li>
                        <li>- Av Humberto Monte, 2929, 60440-593 Pici-Fortaleza-CE</li>
                        <li>- Torre Norte 11° Andar, Sala-1108 </li>
                    </ul>
                </div>


                <div className="footer-item">
                    <h3>Redes Sociais</h3>
                    <ul className="redes-sociais">
                        <li><a rel="noreferrer" href="https://www.linkedin.com/company/sete-inform%C3%A1tica/mycompany/" title="LinkedIn" target="_blank"><FaLinkedin size={27} /></a></li>
                        <li><a rel="noreferrer" href="https://www.instagram.com/sete7informatica/" title="Instagram" target="_blank"><FaInstagramSquare size={27} /></a></li>
                        <li><a rel="noreferrer" href="https://pt-br.facebook.com/sete7informatica/" title="Facebook" target="_blank"> <FaFacebookSquare size={27} /></a></li>
                    </ul>
                </div>
            </div>

            <div className="copy">
                <div className="container item-copy">
                    <p className="texto-copy">{`Sete informática - ${new Date().getFullYear()} Todos direitos reservados.`}</p>
                    <i className="texto-copy">Desenvolvido<strong> por nós ❤️</strong></i>
                </div>
            </div>
        </footer>
    );
}

export default Rodape;