import Menu from '../../components/Menu'
import Inicio from "../../components/Inicio";
import Sobre from '../../components/Sobre';
import Equipe from '../../components/Equipe';
import Contato from '../../components/Contato';
import Quebra from '../../components/Quebra';
import Rodape from '../../components/Rodape';
import Loading from '../../components/Loading';

const Site = () => (
    <>
        <Loading />
        <Menu />
        <Inicio />
        <Sobre title="Sobre" />
        <Equipe title="Seteanos" />
        <Contato title="Contato" />
        <Quebra />
        <Rodape />
    </>
);

export default Site;