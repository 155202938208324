import React, { useRef, useState } from "react";
import './styles.css';
import emailjs from 'emailjs-com';

import { useLoading } from "../../hooks/useLoading";

const Contato = ({ title }) => {

    const form = useRef();
    const [successMsg, setSuccessMsg] = useState(false);
    const { setLoading } = useLoading();

    const handleSendEmail = (e) => {
        setLoading(true)
        e.preventDefault();
        const SERVICE = 'service_neeh6po';
        const TEMPLATE = 'template_361oirb';
        const PUBLIC_KEY = 'lPF-j8wL9_VcfBf9u';

        emailjs.sendForm(SERVICE, TEMPLATE, form.current, PUBLIC_KEY)
            .then(result => {
                setSuccessMsg(true)
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message)
                setLoading(false)
            })
    };

    return (
        <section className="contato">
            <h1 id="contato" className="titulo-white">{title}</h1>
            <p className="subtitulo-white">tire suas dúvidas com a gente.</p>
            <div className="container">
                {successMsg ?
                    <div className="container-form success-msg-center">
                        <i className="success-msg">
                            Obrigado pelo o contato!😊👌
                        </i>
                    </div>
                    :
                    <div className="container-form">
                        <form ref={form} className="form" onSubmit={handleSendEmail}>
                            <div className="form-item ">
                                <label className="control-label" htmlFor="nome">Nome </label>
                                <input className="form-control" id="nome" type="text" name="nome" maxLength={50} required placeholder="nome" />
                            </div>
                            <div className="form-item ">
                                <label className="control-label" htmlFor="email">Email </label>
                                <input className="form-control" id="email" type="email" name="email" maxLength={100} required placeholder="email" />
                            </div>
                            <div className="form-item ">
                                <label className="control-label" htmlFor="telefone">Telefone </label>
                                <input className="form-control" id="telefone" type="tel" name="telefone" maxLength={11} placeholder="(xx) xxxxx-xxxx" />
                            </div>
                            <div className="form-item ">
                                <label className="control-label" htmlFor="mensagem">Mensagem </label>
                                <textarea className="form-control" rows="8" id="mensagem" name="mensagem" placeholder="descreva o assunto..." maxLength={500} required></textarea>
                            </div>
                            <button type="submit" className="btn-enviar">Enviar</button>
                        </form>
                    </div>
                }
            </div>
        </section>
    );
}

export default Contato;