import React, { useEffect } from "react";
import './styles.css';

const Menu = () => {

    useEffect(() => {

        let isActive = true;
        const ac = new AbortController();

        class MobileNavBar {
            constructor(mobileMenu, navLista, navLinks) {
                this.mobileMenu = document.querySelector(mobileMenu);
                this.navLista = document.querySelector(navLista);
                this.navLinks = document.querySelectorAll(navLinks);
                this.activeClass = "active";
                this.activeClick = this.activeClick.bind(this);
            }
            animacaoLinks() {
                this.navLinks.forEach((link, index) => {
                    link.style.animation
                        ? (link.style.animation = "")
                        : (link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`);
                });
            }
            activeClick() {
                this.navLista.classList.toggle(this.activeClass);
                this.mobileMenu.classList.toggle(this.activeClass);
                this.animacaoLinks();
            }
            adicionarEventoClick() {
                this.mobileMenu.addEventListener("click", this.activeClick);
            }
            init() {
                if (this.mobileMenu) {
                    this.adicionarEventoClick();
                }
                return this;
            }
        }
        const mobileNavBar = new MobileNavBar(
            ".mobile-menu",
            ".nav-lista",
            ".nav-lista li",
        );
        if (isActive) {
            mobileNavBar.init();
        }

        return () => {
            isActive = false;
            ac.abort();
        }
    }, [])

    return (
        <header>
            <nav>
                <a className="logo" href="#inicio"> SE7E INFORMÁTICA</a>
                <div className="mobile-menu">
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
                <ul className="nav-lista">
                    <li><a href="#inicio">INÍCIO</a></li>
                    <li><a href="#sobre">SOBRE</a></li>
                    <li><a href="#equipe">SETEANOS</a></li>
                    <li><a href="#contato">CONTATO</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Menu;