import React from 'react'
import './styles.css';

const Quebra = () => {
    return (
        <section className="quebra">
            <div className="mencao-quebra">
                <p>"A tecnologia não é nada sem o talento humano."</p>
                <cite>SE7E INFORMÁTICA</cite>
            </div>
        </section>
    );
}

export default Quebra;
