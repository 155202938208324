import React from "react";
import './styles.css';
import bg from '../../assets/equipe1.jpg'

const Equipe = ({ title }) => {
    return (
        <main className="seteanos">

            <h1 id="equipe" className="titulo-white">{title}</h1>
            <p className="subtitulo-white">equipe sete informática.</p>

            <div className="container grid-seteanos">
                <div className="box-1-seteanos">
                    <img src={bg} alt="seteanos" className="img-seteanos" />
                </div>
                <div className="box-2-seteanos">
                    <h4 className="titulo-interno">Colaboradores</h4>
                    <p className="descricao">A sete informática hoje tem colaboradores que estão sempre em crescimento profissional e pessoal.
                        Todos bem capacitados e especializados para atenderem objetivos que a empresa tem como planos a serem alcançados.
                        Estão sempre em constante aprendizado e aperfeiçoamento, para assim dar segmento nos projetos idealizados.
                        <br />
                        Temos a autonomia e capacidade de agregar outros seguimentos de mercado para valorizar nossos conhecimentos.
                        A empresa preza sempre pelo o bem estar de seus colaboradores, logo possui um ambiente de trabalho voltado para garanti-lo, e entende que cada um possui suas particularidades.
                        A organização que investe em seus colaboradores está investindo no seu próprio sucesso.
                        <br /><br />
                        <strong>#somosSE7E</strong>🎉🤳
                    </p>
                </div>

                <div className="box-3-seteanos">
                    <iframe width="100%" height="365" src="https://www.youtube.com/embed/Uq1n6wKmnaE"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    >
                    </iframe>
                    <cite style={{ color: '#d1d1d1' }}>seguimento portuário (ZPE CEARÁ)</cite>
                </div>
            </div>
        </main>

    );
}

export default Equipe;